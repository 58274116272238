<template>
    <h1>
        depresión ansiedad miedo tristeza melancólico infeliz abatido
    </h1>
  <div class="call-us">
    <h3>
      ¿ No le ves sentido a tu vida ?
    </h3>
    <h4>
      Conversemos! Llamanos a cualquier hora:
    </h4>
    <p>
      <a class="phonecall" href="tel:+57604-444-44-48">(+57) (604) 444 44 48</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CallUs',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    color: white;
}
a.phonecall{
  border: 1px solid #1f8bea;
  border-radius: 3px;
  background-color: #1f8bea;
  color: white;
  padding: 10px;
  text-decoration: none;
}
a.phonecall:hover{
  border: 1px solid black;
  background-color: #2195fc;
  color: white;
  text-decoration: none;
}
</style>
